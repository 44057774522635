/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-12-14 11:25:12
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2022-12-14 15:24:59
 * @FilePath: /PPTist/src/main.ts
 */
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import router from '@/router'
import App from './App.vue'
import './registerServiceWorker'

import '@icon-park/vue-next/styles/index.css'
import 'prosemirror-view/style/prosemirror.css'
import 'animate.css'

import '@/assets/styles/prosemirror.scss'
import '@/assets/styles/global.scss'
import '@/assets/styles/antd.scss'
import '@/assets/styles/font.scss'

import Icon from '@/plugins/icon'
import Directive from '@/plugins/directive'

const app = createApp(App)

app.use(router)
app.use(Icon)
app.use(Directive)

app.use(createPinia())
app.mount('#app')
