/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-12-14 17:44:38
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2022-12-15 15:20:43
 * @FilePath: /PPTist/src/api/http.ts
 */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import showCodeMessage from '@/api/code'
import { message } from 'ant-design-vue'
import { getToken } from '@/utils/token'

const BASE_PREFIX = process.env.VUE_APP_API

// 创建实例
const axiosInstance: AxiosInstance = axios.create({
  // 前缀
  baseURL: BASE_PREFIX,
  // 超时
  timeout: 1000 * 30,
  // 请求头
  headers: {
    'Content-Type': 'application/json',
  },
})

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken()
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: token,
      }
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 200) {
      const { code, result } = response.data
      if (code !== 1) {
        const error = response.data.message || 'ERROR'
        message.error(error)
        return Promise.reject(error)
      }
      return result
    }
    return response
  },
  (error: AxiosError) => {
    const { response } = error
    if (response) {
      message.error(showCodeMessage(response.status))
      return Promise.reject(response.data)
    }
    message.warn('网络连接异常,请稍后再试!')
    return Promise.reject(error)
  },
)
const service = {
  get<T = any>(url: string, data?: object): Promise<T> {
    return axiosInstance.get(url, { params: data })
  },

  post<T = any>(url: string, data?: object): Promise<T> {
    return axiosInstance.post(url, data)
  },

  put<T = any>(url: string, data?: object): Promise<T> {
    return axiosInstance.put(url, data)
  },

  delete<T = any>(url: string, data?: object): Promise<T> {
    return axiosInstance.delete(url, data)
  },
}

export default service
