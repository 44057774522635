/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2023-01-03 15:26:28
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2023-01-03 17:34:17
 * @FilePath: /PPTist/src/api/upload.ts
 */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import service from './http'
import { useOssStore } from '@/store'

import { formatToDate } from '@/utils/dateFormat'

const Api = {
  GetOssConfig: '/ajax/getOssConfig'
}

export const getOssConfig = () => service.get(Api.GetOssConfig)

export const uploadToOss = async (file: File) => {
  const ossStore = useOssStore()
  const ossConfig = ossStore.getConfig
  const axiosInstance: AxiosInstance = axios.create({
    timeout: 30000,
    headers: {
      'Content-type': 'multipart/form-data;charset=UTF-8',
    }
  })
  const fileName = file.name
  const date = formatToDate(new Date(), 'YYYYMMDD').trim()
  const key = `${ossConfig.dir}${date}/${fileName}`
  const data = {
    key,
    policy: ossConfig.policy,
    OSSAccessKeyId: ossConfig.accessid,
    success_action_status: 200,
    Signature: ossConfig.signature,
    file,
  }
  return await axiosInstance.post(ossConfig.host, data).then((res) => {
    return `${ossConfig.cdn}/${key}`
  }).catch((err) => {
    console.log(err)
  })
}
