/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-12-15 12:04:03
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2022-12-15 12:45:09
 * @FilePath: /PPTist/src/api/user.ts
 */
import service from './http'

const Api = {
  GetUserInfo: '/user/getInfo'
}

export const getUserInfo = () => service.get(Api.GetUserInfo)