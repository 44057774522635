/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2023-01-03 15:30:13
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2023-01-03 15:41:00
 * @FilePath: /PPTist/src/store/oss.ts
 */
import { defineStore } from 'pinia'

import { getOssConfig } from '@/api/upload'

export interface OssState {
  accessid: string;
  host: string;
  policy: string;
  signature: string;
  expire: string;
  callback: string;
  dir: string;
  cdn: string;
}

export const useOssStore = defineStore('oss', {
  state: (): OssState => ({
    accessid: '',
    host: '',
    policy: '',
    signature: '',
    expire: '',
    callback: '',
    dir: '',
    cdn: '',
  }),

  getters: {
    getConfig(state) {
      return state
    }
  },
  
  actions: {
    setConfig(config: OssState) {
      this.accessid = config.accessid
      this.host = config.host
      this.policy = config.policy
      this.signature = config.signature
      this.expire = config.expire
      this.callback = config.callback
      this.dir = config.dir
      this.cdn = config.cdn
    },
    async getOssConfig() {
      const { config } = await getOssConfig()
      this.setConfig(config)
    }
  }
})