/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-12-14 15:03:22
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2023-01-03 18:36:14
 * @FilePath: /PPTist/src/router/index.ts
 */
import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useUserStore, useOssStore } from '@/store'
import { getToken } from '@/utils/token'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'PPT',
    },
    component: () => import('@/views/home.vue')
  },
  {
    path: '/design/:id/:token',
    name: 'Design',
    meta: {
      title: '设计 PPT',
    },
    component: () => import('@/views/design.vue')
  },
  {
    path: '/player',
    name: 'Player',
    meta: {
      title: '演示',
    },
    component: () => import('@/views/player.vue'),
    beforeEnter: (to, from, next) => {
      if (!to.query.file) {
        next('/404')
      }
      else {
        next()
      }
    }
  },
  {
    path: '/403',
    name: '403',
    meta: {
      title: '没有权限',
    },
    component: () => import('@/views/Page/403.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '页面 404 Not found',
    },
    component: () => import('@/views/Page/404.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    meta: {},
    name: 'NotFound',
    redirect: '/404'
  }
]

const router: Router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  document.title = (to.meta.title as string) ? `${to.meta.title as string} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
  if (!NProgress.isStarted()) {
    NProgress.start()
  }
  const whiteList = ['/', '/player', '/403', '/404']
  if (whiteList.indexOf(to.path) > -1) {
    next()
    NProgress.done()
  }
  else {
    const localToken = getToken()
    const { token } = to.params
    const authToken: string | null = token as string || localToken // 优先使用参数
    if (!authToken) {
      next('/403')
      NProgress.done()
    }
    const userStore = useUserStore()
    const ossStore = useOssStore()
    try {
      userStore.setToken(authToken as string)
      if (!userStore.getNickname) {
        ossStore.getOssConfig()
        await userStore.getUserInfo()
      }
      next()
      NProgress.done()
    }
    catch (error) {
      console.log(error)
      userStore.logout()
      next('/403')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
