/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-12-14 17:58:39
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2022-12-16 12:18:49
 * @FilePath: /PPTist/src/store/user.ts
 */
import { defineStore } from 'pinia'

import { getUserInfo } from '@/api/user'
import { setToken, delToken } from '@/utils/token'

export interface UserState {
  username: string;
  nickname: string;
  token: string;
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    username: '',
    nickname: '',
    token: '',
  }),

  getters: {
    getToken(state) {
      return state.token
    },
    getUsername(state) {
      return state.username
    },
    getNickname(state) {
      return state.nickname
    }
  },

  actions: {
    setToken(token: string) {
      this.token = token
      setToken(token)
    },
    setUsername(username: string) {
      this.username = username
    },
    setNickname(nickname: string) {
      this.nickname = nickname
    },
    async getUserInfo() {
      const { username, realName } = await getUserInfo()
      this.setNickname(realName)
      this.setUsername(username)
    },
    logout() {
      delToken()
    }
  }
})