const BASE_TOKEN_URL = process.env.VUE_APP_TOKEN_URL

export const setToken = (token: string) => {
  localStorage.setItem(BASE_TOKEN_URL, token)
}

export const getToken = () => {
  return localStorage.getItem(BASE_TOKEN_URL)
}

export const delToken = () => {
  localStorage.removeItem(BASE_TOKEN_URL)
}