<!--
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-12-14 11:25:11
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2022-12-14 15:51:15
 * @FilePath: /PPTist/src/App.vue
-->
<template>
  <router-view></router-view>
</template>
<script lang='ts' setup>
</script>
<style lang="scss">
#app {
  height: 100%;
}
</style>