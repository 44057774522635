/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2023-01-03 16:03:27
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2023-01-03 16:08:25
 * @FilePath: /PPTist/src/utils/dateFormat.ts
 */
import dayjs from 'dayjs'
export const formatToDate = (date: Date, format = 'YYYY-MM-DD') => {
  return dayjs(date).format(format)
}